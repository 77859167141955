import $ from 'jquery';

global.$ = $;
global.jQuery = $;
global.jquery = $;

import 'jquery-ui-dist/jquery-ui.min';
import 'bootstrap';
import 'formvalidation';
import 'formvalidation/dist/js/framework/bootstrap';
import 'leaflet';
import 'leaflet-providers';
require('leaflet.markercluster');
require("regenerator-runtime/runtime");
